import React from 'react';
import { Menu, Icon, Layout, message } from 'antd';

import { logout } from '../../../actions/auth.action';
import { ContextStore } from '../../../store/ContextStore';
import { parseError } from '../../../languages/error.language';
import { commonLang } from '../../../languages/common.language';
import { Z_INDEX_MAIN_HEADER } from '../../../constants/dom.constant';
import {
  ROUTE_HOME,
  ROUTE_TAGS,
  ROUTE_LESSONS,
  ROUTE_PROJECTS,
  ROUTE_QUESTIONS,
  ROUTE_PROJECTS_ORDER,
} from '../../../constants/app.constant';
import { 
  getTags,
  getLessons,
  getProjects,
  getQuestions,
} from '../../../actions/project.action';


function MainHeader(props) {
  const { dispatch } = React.useContext(ContextStore);

  async function handleLogout(){
    try{
      dispatch(await logout());
      window.location = ROUTE_HOME;
      return;
    }catch(e){
      message.error(parseError(e));
      return;
    }
  }

  async function handleChangePage(route) {
    switch(route){
      case ROUTE_TAGS:
        dispatch(await getTags());
        break;
      case ROUTE_PROJECTS:
      case ROUTE_PROJECTS_ORDER:
        dispatch(await getProjects());
        break;
      case ROUTE_LESSONS:
        dispatch(await getLessons());
        break;
      case ROUTE_QUESTIONS:
        dispatch(await getQuestions());
        break;
      default: break;
    }
    props.history.push(route);
  }

  return (
    <Layout.Header style={{ zIndex: Z_INDEX_MAIN_HEADER, height: `64px` }}>
      <Menu selectedKeys={[props.history.location.pathname]} mode="horizontal" style={{lineHeight: `63px`}}>
        <Menu.Item style={{border: `none`, color: `#4F4F4F`, cursor: `default`, fontWeight: `700`, padding: `0px 20px 0px 0px`}}>
          {commonLang.cms}
        </Menu.Item>

        <Menu.Item onClick={() => handleChangePage(ROUTE_PROJECTS)} key={ROUTE_PROJECTS}>
          <Icon type="project" />{commonLang.projects}
        </Menu.Item>

        <Menu.Item onClick={() => handleChangePage(ROUTE_PROJECTS_ORDER)} key={ROUTE_PROJECTS_ORDER}>
          <Icon type="project" />{commonLang.projects_order}
        </Menu.Item>

        <Menu.Item onClick={() => handleChangePage(ROUTE_TAGS)} key={ROUTE_TAGS}>
          <Icon type="tags" />{commonLang.tags}
        </Menu.Item>

        <Menu.Item onClick={() => handleChangePage(ROUTE_LESSONS)} key={ROUTE_LESSONS}>
          <Icon type="schedule" />{commonLang.lessons}
        </Menu.Item>

        <Menu.Item onClick={() => handleChangePage(ROUTE_QUESTIONS)} key={ROUTE_QUESTIONS}>
          <Icon type="container" />{commonLang.questions}
        </Menu.Item>

        <Menu.Item key="/logout" onClick={handleLogout}>
          <Icon type="logout" />{commonLang.logout}
        </Menu.Item>
      </Menu>
    </Layout.Header>
  );
}
export default MainHeader;
