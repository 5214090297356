import LocalizedStrings from 'react-localization';
 
export const publicLang = new LocalizedStrings({
	'en-us':{
		"login": "Login",
		"footer": "Copyright © Cherrypicks 2019. All Rights Reserved.",
		"smart_lab": "AI Smart Lab",
		"admin_portal": "Admin Portal",
		"username": "Username",
		"password": "Password",
		"no_permission": "No permission",
		"login_failed": "Login failed",
	},
});

export default { publicLang };
