import { getAppPlatformCode } from '../utils/app';

// <----- external app IDs and secrets
export const GOOGLE_ANALYTICS = process.env.REACT_APP_GOOGLE_ANALYTICS;

// <----- external paths
export const PATH_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// <----- platforms
export const PLATFORM_DESKTOP = 0;
export const PLATFORM_IOS = 1;
export const PLATFORM_ANDROID = 2;
export const PLATFORM_WINDOWS_PHONE = 3;
export const PLATFORM = getAppPlatformCode(window.navigator.userAgent || window.navigator.vendor || window.opera);
export const IS_MOBILE_PLATFORM = PLATFORM===PLATFORM_IOS || PLATFORM===PLATFORM_ANDROID; // || true;

// <----- system
export const NODE_ENV = process.env.NODE_ENV;
export const APP_SERVER_LOCAL = 'Local';
export const APP_SERVER_DEVELOPMENT = 'Development';
export const APP_SERVER_STAGING = 'Staging';
export const APP_SERVER_PRODUCTION = 'Production';
export const APP_SERVER = process.env.REACT_APP_SERVER ? process.env.REACT_APP_SERVER : APP_SERVER_LOCAL;
export const APP_VERSION = process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : '0.0.1';
export const LANGUAGE_EN_US = 'en-us';

// <----- actions
export const ACTION_CHANGE_LANGUAGE = 'action_change_language';

// <----- routes
export const ROUTE_HOME = '/';
export const ROUTE_PROJECTS = '/projects';
export const ROUTE_TAGS = '/tags';
export const ROUTE_PROJECT = '/project';
export const ROUTE_LESSONS = '/lessons';
export const ROUTE_QUESTIONS = '/questions';
export const ROUTE_QUESTION = '/question';
export const ROUTE_PROJECT_LESSON = '/project_lesson';
export const ROUTE_PROJECT_LESSON_CONTENT = '/project_lesson_content';
export const ROUTE_PROJECTS_ORDER = '/projects_order';