
export const init = async () => {
  window.languagePluginUrl = "https://pyodide.cdn.iodide.io/";
  const script = document.createElement("script");
  script.src = "/pyodide/pyodide.js";
  script.async = true;
  document.body.appendChild(script);
  return true;
}

export const validate = (code, callback) => {
  window.languagePluginLoader.then(() => {
    try{
      window.pyodide.runPythonAsync(code, (data) => {
        callback(data);
      }).then(result => {
        callback(result);
      }, (error) => {
        callback(false);
      })
    }
    catch(e){
      callback(false);
    }
  });
}

export default {
  init,
  validate,
};
