import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Input, Modal, Row, Col } from 'antd';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-monokai";

import { ContextStore } from '../../../store/ContextStore';
import { commonLang } from '../../../languages/common.language';
import { ROUTE_QUESTIONS } from '../../../constants/app.constant';
import { RICH_TEXT } from '../../../constants/dom.constant';
import { 
  updateQuestion, 
  updateOptions,
} from '../../../actions/project.action';
import EditableTable from '../Common/EditableTable';
import Previewer from '../Common/Previewer';
import { init, validate } from '../Common/Pyodide';

function Question(props) {
  const { dispatch, project } = React.useContext(ContextStore);
  const [values, setValue] = React.useState({
    ...project.question,
    loading: false,
  });

  const columns = [
    {
      title: '*content en-US',
      dataIndex: 'content-en-US',
      editable: true,
      width: 200,
      textarea: true,
    },
    {
      title: '*content zh-HK',
      dataIndex: 'content-zh-HK',
      editable: true,
      width: 200,
      textarea: true,
    },
    {
      title: '*content zh-CN',
      dataIndex: 'content-zh-CN',
      editable: true,
      width: 200,
      textarea: true,
    },
    {
      title: '*correct',
      dataIndex: 'correct',
      editable: true,
    },
  ];

  const newData = {
    'content-en-US': 'Option title',
    'content-zh-HK': 'Option title',
    'content-zh-CN': 'Option title',
    'correct': '0',
  };

  function changeValue(name, data) {
    let value = {};
    value[name] = data;
    setValue({...values, ...value});
  }

  function changeHighlighted(e) {
    setValue({...values, highlighted: e.target.value ? e.target.value.split(',') : []})
  }

  async function handleSave(data) {
    setValue({...values, loading: true});

    let question = {};
    if(data.type==="image"){
      question['image'] = data['image-en-US'];
    }
    else if(data.type==="editor"){
      question['code'] = data['code'];
      question['pre_define'] = data['pre_define'];
      question['output_code'] = data['output_code'];
      question['expected_output'] = data['expected_output'];
      question['highlighted'] = data['highlighted'] ? JSON.stringify(data['highlighted'].map((unit) => parseInt(unit))) : "[]";
      
    }
    else if(data.type==="code"){
      question['code'] = data['code'];
      question['highlighted'] = data['highlighted'] ? JSON.stringify(data['highlighted'].map((unit) => parseInt(unit))) : "[]";
    }

    if(data['introduction-en-US']){
      question['introduction'] = data['introduction-en-US'];
    }

    data.question = question;
    dispatch(await updateQuestion(data));
    Modal.success({
      title: commonLang.updated,
      onOk() {
        return new Promise((resolve, reject) => {
          window.location.reload();
        }).catch(() => console.log('Oops errors!'));
      },
    });
    setValue({...values, loading: false});
  }

  async function generateOutput(value) {
    setValue({
      ...value,
      loading: true,
    });
    await validate(value.pre_define+'\n'+value.code+'\n'+value.output_code, validateCallback);
  }

  function validateCallback(result) {
    result = result ? JSON.stringify(result) : "";
    setValue({
      ...values,
      loading: false,
      expected_output: result,
    });
  }

  function parseMedia(type) {
    switch(type){
      case 'code':
        const annotations = values.highlighted ? values.highlighted.map((unit, index) => {return {row: unit-1, type: 'warning', text: 'highlighted'}}) : [];
        return (
          <div>
            <div>Highlighted line numbers (separate by ",")</div>
            <Input defaultValue={values.highlighted} onChange={changeHighlighted} />
            <AceEditor
              mode="python"
              theme="monokai"
              style={{width: `100%`}}
              value={values.code ? values.code : ""}
              wrapEnabled={true}
              showGutter={true}
              showPrintMargin={false}
              annotations={annotations}
              onChange={(code) => changeValue('code', code)}
            />
          </div>
        );
      case 'editor':
        const annotations2 = values.highlighted ? values.highlighted.map((unit, index) => {return {row: unit-1, type: 'warning', text: 'highlighted'}}) : [];
        return (
          <Row gutter={16}>
            <Col md={12}>
              <h3>Question Codes</h3>
              <AceEditor
                mode="python"
                theme="monokai"
                style={{width: `100%`}}
                value={values.code ? values.code : ""}
                wrapEnabled={true}
                showGutter={true}
                showPrintMargin={false}
                annotations={annotations2}
                onChange={(code) => changeValue('code', code)}
              />
              <br />
            </Col>
            <Col md={12}>
              <h3>Highlighted line numbers (separate by ",")</h3>
              <Input defaultValue={values.highlighted} onChange={changeHighlighted} />

              <br /><br />
              <h3>Pre-defined Codes</h3>
              <AceEditor
                mode="python"
                theme="monokai"
                style={{width: `100%`, height: `100px`}}
                value={values.pre_define ? values.pre_define : ""}
                wrapEnabled={true}
                showGutter={true}
                showPrintMargin={false}
                annotations={annotations2}
                onChange={(code) => changeValue('pre_define', code)}
              />

              <br />
              <h3>Code to generate output</h3>
              <AceEditor
                mode="python"
                theme="monokai"
                style={{width: `100%`, height: `100px`}}
                value={values.output_code ? values.output_code : ""}
                wrapEnabled={true}
                showGutter={true}
                showPrintMargin={false}
                annotations={annotations2}
                onChange={(code) => changeValue('output_code', code)}
              />

              <br />
              <h3>Expected output</h3>
              <Input value={values.expected_output} onChange={(e) => changeValue('expected_output', e.target.value)} />

              <br /><br />
              <Button type="primary" loading={values.loading} onClick={() => generateOutput(values)}>Generate output</Button>
            </Col>
          </Row>
        );
      case 'image':
        return(
          <Row gutter={16}>
            <Col md={8}>
              <h3>image-en-US</h3>
              <Input defaultValue={values['image-en-US']} onChange={(e) => changeValue('image-en-US', e.target.value)} />
              <Previewer mime="image/jpeg" src={values['image-en-US']} />
            </Col>
            <Col md={8}>
              <h3>image-zh-HK</h3>
              <Input defaultValue={values['image-zh-HK']} onChange={(e) => changeValue('image-zh-HK', e.target.value)} />
              <Previewer mime="image/jpeg" src={values['image-zh-HK']} />
            </Col>
            <Col md={8}>
              <h3>image-zh-CN</h3>
              <Input defaultValue={values['image-zh-CN']} onChange={(e) => changeValue('image-zh-CN', e.target.value)} />
              <Previewer mime="image/jpeg" src={values['image-zh-CN']} />
            </Col>
          </Row>
        );
      default:
        return <div />;
    }
  }

  useEffect(() => {
    if(project.question.type==="editor")
      init();
  }, [])

  return (
    <div style={{width: `100%`}}>
      <h3 style={{marginBottom: 16}}><Link to={ROUTE_QUESTIONS}>{commonLang.questions}</Link> / {project.question['content-en-US']}</h3>

      <span style={{float: `right`}}>
        <Button type="primary" style={{marginLeft: 8}} onClick={() => handleSave(values)} loading={values.loading}>{commonLang.save}</Button>
      </span>
      <h2>Introduction</h2>
      <h3>introduction-en-US</h3>
      <CKEditor
        editor={ClassicEditor}
        data={project.question['introduction-en-US']}
        config={RICH_TEXT}
        onBlur={( event, editor ) => changeValue('introduction-en-US', editor.getData())}
      />
      <br /><h3>introduction-zh-HK</h3>
      <CKEditor
        editor={ClassicEditor}
        data={project.question['introduction-zh-HK']}
        config={RICH_TEXT}
        onBlur={( event, editor ) => changeValue('introduction-zh-HK', editor.getData())}
      />
      <br /><h3>introduction-zh-CN</h3>
      <CKEditor
        editor={ClassicEditor}
        data={project.question['introduction-zh-CN']}
        config={RICH_TEXT}
        onBlur={( event, editor ) => changeValue('introduction-zh-CN', editor.getData())}
      />

      <hr />
      <h2>Type ({values.type})</h2>
      { parseMedia(values.type) }

      <hr />
      <h2>Options</h2>
      <EditableTable 
        data={project.question.options}
        columns={columns}
        newData={newData}
        api={updateOptions}
      />
    </div>
  );
}
export default Question;
