import React from 'react';
import { ContextStore } from '../../../store/ContextStore';
import { updateLessons } from '../../../actions/project.action';
import EditableTable from '../Common/EditableTable';

function Lessons(props) {
	const { project } = React.useContext(ContextStore);

	const columns = [
		{
			title: '*title en-US',
			dataIndex: 'title-en-US',
			editable: true,
		},
		{
			title: '*title zh-HK',
			dataIndex: 'title-zh-HK',
			editable: true,
		},
		{
			title: '*title zh-CN',
			dataIndex: 'title-zh-CN',
			editable: true,
		},
		{
			title: '*description en-US',
			dataIndex: 'description-en-US',
			editable: true,
		},
		{
			title: '*description zh-HK',
			dataIndex: 'description-zh-HK',
			editable: true,
		},
		{
			title: '*description zh-CN',
			dataIndex: 'description-zh-CN',
			editable: true,
		},
		{
			title: '*extra',
			dataIndex: 'extra',
			editable: true,
		},
	];

	const newData = {
		'title-en-US': 'Lesson name',
		'title-zh-HK': 'Lesson name',
		'title-zh-CN': 'Lesson name',
		'description-en-US': 'Lesson description',
		'description-zh-HK': 'Lesson description',
		'description-zh-CN': 'Lesson description',
		'extra': '0',
	};

	return (
		<React.Fragment>
			<EditableTable 
				data={project.lessons}
				columns={columns}
				newData={newData}
				api={updateLessons}
				hideDelete={true}
			/>
		</React.Fragment>
	);
}
export default Lessons;
