import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

import { ContextStore } from '../../../store/ContextStore';
import { commonLang } from '../../../languages/common.language';
import { 
  ROUTE_PROJECTS, 
  ROUTE_PROJECT, 
  ROUTE_PROJECT_LESSON_CONTENT,
} from '../../../constants/app.constant';
import { 
  updateContents, 
  getProjectLessonContent, 
  getQuestions,
} from '../../../actions/project.action';
import EditableTable from '../Common/EditableTable';
import Previewer from '../Common/Previewer';
import Uploader from '../Common/Uploader';

function Projects(props) {
  const { dispatch, project } = React.useContext(ContextStore);
  const columns = [
    {
      title: '*title en-US',
      dataIndex: 'title-en-US',
      width: 200,
      editable: true,
      textarea: true,
    },
    {
      title: '*title zh-HK',
      dataIndex: 'title-zh-HK',
      width: 200,
      editable: true,
      textarea: true,
    },
    {
      title: '*title zh-CN',
      dataIndex: 'title-zh-CN',
      width: 200,
      editable: true,
      textarea: true,
    },
    {
      title: '*priority',
      dataIndex: 'priority',
      editable: true,
      sorter: (a, b) => a.priority - b.priority,
    },
    {
      title: '*type',
      dataIndex: 'type',
      editable: true,
    },
    {
      title: 'preview',
      dataIndex: 'preview',
      render: (text, record) => <Previewer mime={record.type} src={record['content-en-US']} />
    },
    {
      title: '^content',
      dataIndex: 'content',
      render: (text, record) => <Button onClick={() => handleClickContent(record)} disabled={Number.isInteger(record.key)}>{commonLang.details}</Button>
    },
  ];

  const newData = {
    'title-en-US': 'Slide title',
    'title-zh-HK': 'Slide title',
    'title-zh-CN': 'Slide title',
    'priority': 0,
    'type': 'text/html',
    'content-en-US': 'https://computerhistory.org/wp-content/uploads/2019/06/16-9-dummy-image2.jpg',
    'content-zh-HK': 'https://computerhistory.org/wp-content/uploads/2019/06/16-9-dummy-image2.jpg',
    'content-zh-CN': 'https://computerhistory.org/wp-content/uploads/2019/06/16-9-dummy-image2.jpg',
    'description-en-US': 'Description',
    'description-zh-HK': 'Description',
    'description-zh-CN': 'Description',
  };

  async function handleClickContent(content) {
    dispatch(await getProjectLessonContent(content));
    dispatch(await getQuestions());
    props.history.push(ROUTE_PROJECT_LESSON_CONTENT);
  }

  return (
      <div style={{width: `100%`}}>
        <h3 style={{marginBottom: 16}}><Link to={ROUTE_PROJECTS}>{commonLang.projects}</Link> / <Link to={ROUTE_PROJECT}>{project.detail['title-en-US']}</Link> / {project.lesson.name}</h3>
        <Uploader />

        <hr />
        <h3>Supported content types</h3>
        <h4>Explore (need to add a tag for category)</h4>
        <ul>
          <li>href/info</li>
          <li>href/article</li>
          <li>href/video</li>
          <li>href/programming</li>
        </ul>

        <h4>Try / Learn</h4>
        <ul>
          <li>application/pdf</li>
          <li>image/bmp</li>
          <li>image/gif</li>
          <li>image/jpeg</li>
          <li>image/png</li>
          <li>application/vnd.ms-powerpoint</li>
          <li>application/msword</li>
          <li>application/vnd.ms-excel</li>
        </ul>

        <h4>Inspect / Assessment (Need to add a question)</h4>
        <ul>
          <li>text/html</li>
        </ul>
        <hr />

        <EditableTable 
          data={project.lesson.content} 
          columns={columns}
          newData={newData}
          api={updateContents}
        />
      </div>
    );
  }
export default Projects;
