import React from 'react';
import { Table, Button } from 'antd';
import { ContextStore } from '../../../store/ContextStore';
import { uploadFile } from '../../../actions/project.action';

function Uploader(props) {
  const { dispatch, project } = React.useContext(ContextStore);
  const fileInput = React.useRef();
  const [values, setValue] = React.useState({
    loading: false,
  });

  async function handleSubmit(event) {
    event.preventDefault();
    setValue({...values, loading: true});
    dispatch(await uploadFile(fileInput.current.files[0]));
    setValue({...values, loading: false});
  }

  const columnData = [
    {
      title: 'name',
      dataIndex: 'name',
    },
    {
      title: 'type',
      dataIndex: 'mime',
    },
    {
      title: 'url',
      dataIndex: 'url',
    },
  ];

  return (
    <form 
      style={{margin: `16px -50px`, border: `1px solid #ddd`, padding: `16px 50px`, background: `#FFF`}} 
      onSubmit={handleSubmit}
    >
      <h3>Upload file to S3</h3>
      <input type="file" ref={fileInput} />
      <Button style={{float: `right`}} type="primary" htmlType="submit" loading={values.loading}>Upload</Button>
      <h4 style={{margin: `16px 0px`}}>Please copy the file type and url to update the record.</h4>
      <Table 
        rowKey="url"
        dataSource={project.files}
        columns={columnData}
        pagination={false}
      />
    </form>
  );
}

export default Uploader;