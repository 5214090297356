import React from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col, Form, Checkbox, message } from 'antd';
import { ContextStore } from '../../../store/ContextStore';
import { commonLang } from '../../../languages/common.language';
import { 
  updateProjectTags, 
} from '../../../actions/project.action';

function Tags(props) {
  const { dispatch } = React.useContext(ContextStore);
  const [values, setValue] = React.useState({
    'added': props.added,
    'tags': props.tags.filter(x => !props.tags.includes(x)),
  });

  function changeTag(e) {
    setValue({...values, added: e});
  }

  function resetTag() {
    setValue({...values, added: props.added});
  }

  async function saveTag() {
    setValue({...values, loading: true});

    const added = values.added.filter(x => !props.added.includes(x));
    const deleted = props.added.filter(x => !values.added.includes(x));

    if(added.length>0 || deleted.length>0){
      dispatch(await updateProjectTags(
        props.target,
        added, 
        deleted
      ));
      message.success(commonLang.updated);
    }
    
    setValue({...values, loading: false});
  }

  const tags = props.tags.map((unit, index) => <Col xs={12} md={8} lg={6} key={unit.key}><Checkbox value={unit.key}>{unit.label}</Checkbox></Col>)
  return (
    <Form layout="vertical">
      <span style={{float: `right`}}>
        <Button type="outlined" style={{marginLeft: 8}} onClick={resetTag}>{commonLang.reset}</Button>
        <Button type="primary" style={{marginLeft: 8}} onClick={saveTag} loading={values.loading}>{commonLang.save}</Button>
      </span>
      <h2>{commonLang.tags}</h2>

      <Form.Item>
        <Checkbox.Group value={values.added} onChange={changeTag}>
          <Row gutter={16}>{tags}</Row>
        </Checkbox.Group>
      </Form.Item>
    </Form>
  );
}

Tags.propTypes = {
  target: PropTypes.string, // uuid
  added: PropTypes.array, // uuids in array format
  tags: PropTypes.array, // each detail of tags in array format
};

export default Tags;
