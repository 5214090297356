import React from 'react';
import { 
  TYPE_AWS,
  TYPE_YOUTUBE,
  TYPE_IMAGE,
  TYPE_PDF,
  TYPE_OFFICE, 
  TYPE_EXPLORE,
} from '../../../constants/dom.constant';

function Previewer(props) {
  const styles = {
    image: {
      width: `auto`,
      height: `auto`,
      maxWidth: `300px`,
      maxHeight: `170px`,
    },
    link: {
      width: `300px`,
      height: `170px`,
      backgroundColor: `#fff`,
      display: `flex`,
      alignItems:  `center`,
    },
    bookmark: {
      width: `64px`,
      height: `64px`,
      margin: `auto`,
    }
  }

  if(TYPE_PDF.includes(props.mime)){
    return <iframe title="google pdf viewer" src={"https://docs.google.com/viewer?url="+props.src+"&embedded=true"} width="300" height="170" frameBorder="0" />
  }
  else if(TYPE_OFFICE.includes(props.mime)){
    return <iframe title="office document viewer" src={"https://view.officeapps.live.com/op/embed.aspx?src="+encodeURI(props.src)} width="300" height="170" frameBorder="0" />
  }
  else if(TYPE_IMAGE.includes(props.mime)){
    return <img alt="slide" style={styles.image} src={props.src} />
  }
  else if(TYPE_YOUTUBE.includes(props.mime)){
    return <img alt="youtube" style={styles.image} src={"https://img.youtube.com/vi/"+(props.src.split('?v=')[1])+"/mqdefault.jpg"} />
  }
  else if(TYPE_AWS.includes(props.mime)){
    return <img alt="amazon" style={styles.image} src="/images/aws.svg" />
  }
  else if(TYPE_EXPLORE.includes(props.mime)){
    switch(props.mime){
      case 'href/info':
        return <img alt="explore" src="/images/Info.svg" />
      case 'href/article':
        return <img alt="explore" src="/images/Article.svg" />
      case 'href/video':
        return <img alt="explore" src="/images/Video.svg" />
      default:
        return <img alt="explore" src="/images/Info.svg" />
    }
  }
  else
    return <div style={styles.link}><img alt="link" style={styles.bookmark} src="/images/bookmark.svg" /></div>
}

export default Previewer;