import React from 'react';
import { Table, Input, Button, Popconfirm, Form, Modal } from 'antd';

import { commonLang } from '../../../languages/common.language';
import { ContextStore } from '../../../store/ContextStore';

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
  state = {
    editing: false,
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = e => {
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) return;
      this.toggleEdit();
      this.props.handleSave({ ...this.props.record, ...values });
    });
  };

  renderCell = form => {
    this.form = form;
    if(this.state.editing){
      return (
        <Form.Item style={{ margin: 0 }}>
          {
            form.getFieldDecorator(this.props.dataIndex, {
              rules: [{
                required: true,
                message: `${this.props.title} is required.`,
              }],
              initialValue: this.props.record[this.props.dataIndex],
            })(
              this.props.textarea
              ? <Input.TextArea ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} autoSize />
              : <Input ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />
            )
          }
        </Form.Item>
      );
    }
    else{
      return (
        <div className="editable-cell-value-wrap" onClick={this.toggleEdit}>
          {this.props.children}
        </div>
      );
    }
  };

  render() {
    return (
      <td {...this.props.restProps}>
        {
          this.props.editable 
          ? <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
          : this.props.children
        }
      </td>
    );
  }
}

function EditableTable(props) {
  const { dispatch } = React.useContext(ContextStore);
  const tableDom = React.useRef();
  const [state, setState] = React.useState({
    dataSource: props.data,
    columns: !props.hideDelete
      ? [
        ...props.columns, {
          title: 'operation',
          dataIndex: 'operation',
          render: (text, record) => <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}><Button type="danger">{commonLang.delete}</Button></Popconfirm>
        }
      ]
      : props.columns,
    loading: false,
  });

  function handleDelete(key) {
    const dataSource = [...tableDom.current.props.dataSource];
    setState({
      ...state,
      dataSource: dataSource.filter(item => item.key !== key),
    });
  }

  function handleAdd() {
    const key = Math.floor(Math.random() * 100000) + 10000;
    setState({
      ...state,
      dataSource: tableDom.current.props.dataSource ? [{...props.newData, key: key}, ...tableDom.current.props.dataSource] : [{...props.newData, key: key}],
    });
  }

  function handleSave(row) {
    const newData = [...tableDom.current.props.dataSource];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {...item, ...row});
    setState({ 
      ...state,
      dataSource: newData,
    });
  }

  function handleReset() {
    setState({ 
      ...state,
      dataSource: props.data,
    });
  }

  async function handleSubmit() {
    setState({ 
      ...state,
      loading: true,
    });

    const original = props.data;
    const current = tableDom.current.props.dataSource;
  
    const originalKey = props.data.map(({key}) => key);
    const currentKey = tableDom.current.props.dataSource.map(({key}) => key);
    const addedKey = currentKey.filter(x => !originalKey.includes(x));
    const deletedKey = originalKey.filter(x => !currentKey.includes(x));

    const added = current.filter(x => !original.includes(x) && addedKey.includes(x.key));
    const updated = current.filter(x => !original.includes(x) && !addedKey.includes(x.key));
    const deleted = original.filter(x => deletedKey.includes(x.key));
    
    if(added.length>0 || updated.length>0 || deleted.length>0){
      dispatch(await props.api({added: added, updated: updated, deleted: deleted}));
      Modal.success({
        title: commonLang.updated,
        onOk() {
          return new Promise((resolve, reject) => {
            window.location.reload();
          }).catch(() => console.log('Oops errors!'));
        },
      })
    }

    setState({ 
      ...state,
      loading: false,
    });
  }

  const columnData = state.columns.map(col => {
    if (!col.editable) return col;
    return {
      ...col,
      onCell: record => ({
        record,
        editable: col.editable,
        textarea: col.textarea,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    };
  });

  return (
    <div style={{width: `100%`}}>
      <div style={{height: `48px`}}>
        <div style={{float: `left`}}>
          * {commonLang.edit_hints}<br />
          ^ {commonLang.detail_hints}
        </div>
        <span style={{float: `right`}}>
          <Button onClick={handleAdd} type="outlined" style={{ marginLeft: 8 }}>{commonLang.add}</Button>
          <Button onClick={handleReset} type="outlined" style={{ marginLeft: 8 }}>{commonLang.reset}</Button>
          <Button onClick={handleSubmit} type="primary" style={{ marginLeft: 8 }} loading={state.loading}>{commonLang.save}</Button>
        </span>
      </div>
      <Table
        ref={tableDom}
        components={{body: {row: EditableFormRow, cell: EditableCell}}}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={state.dataSource}
        columns={columnData}
        style={{width: `100%`}}
        scroll={{ x: true }}
      />
    </div>
  );
}

export default EditableTable;