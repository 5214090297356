import {
	ACTION_PROJECTS,
	ACTION_PROJECT_DETAIL,
	ACTION_PROJECT_LESSON,
	ACTION_LESSON_CONTENT,
	ACTION_QUESTIONS,
	ACTION_QUESTION_DETAIL,
	ACTION_LESSONS,
	ACTION_TAGS,
	ACTION_UPDATE_TAGS,
	ACTION_UPDATE_LESSONS,
	ACTION_UPDATE_OPTIONS,
	ACTION_UPDATE_PROJECTS,
	ACTION_UPDATE_CONTENT,
	ACTION_UPDATE_CONTENTS,
	ACTION_UPDATE_QUESTION,
	ACTION_UPDATE_QUESTIONS,
	ACTION_UPDATE_PROJECT_TAGS,
	ACTION_UPDATE_PROJECT_LESSONS,
	ACTION_UPDATE_RELATED_PROJECTS,
	ACTION_UPDATE_PROJECT_DESCRIPTIONS,
	ACTION_UPLOAD_FILE,
} from '../constants/project.constant';
 
function projectReducer(state, action) {
	const payload = action.payload;

	switch (action.key) {
		case ACTION_PROJECTS:
		case ACTION_UPDATE_PROJECTS:
			const projects = parseProjects(payload);
			localStorage.setItem('project.all', JSON.stringify(projects));
			return Object.assign({}, state, {
				...state,
				all: projects,
			});
		case ACTION_PROJECT_DETAIL:
		case ACTION_UPDATE_PROJECT_TAGS:
		case ACTION_UPDATE_PROJECT_LESSONS:
		case ACTION_UPDATE_RELATED_PROJECTS:
		case ACTION_UPDATE_PROJECT_DESCRIPTIONS:
			const {detail, lesson, content} = parseProjectDetail(payload, state.detail, state.lesson, state.content);
			localStorage.setItem('project.detail', JSON.stringify(detail));
			localStorage.setItem('project.lesson', JSON.stringify(lesson));
			localStorage.setItem('project.content', JSON.stringify(content));
			return Object.assign({}, state, {
				...state,
				content: content,
				lesson: lesson,
				detail: detail,
			});
		case ACTION_TAGS:
		case ACTION_UPDATE_TAGS:
			const tags = parseTags(payload);
			localStorage.setItem('project.tags', JSON.stringify(tags));
			return Object.assign({}, state, {
				...state,
				tags: tags,
			});
		case ACTION_LESSONS:
		case ACTION_UPDATE_LESSONS:
			const lessons = parseLessons(payload);
			localStorage.setItem('project.lessons', JSON.stringify(lessons));
			return Object.assign({}, state, {
				...state,
				lessons: lessons,
			});
		case ACTION_PROJECT_LESSON:
			localStorage.setItem('project.lesson', JSON.stringify(payload));
			return Object.assign({}, state, {
				...state,
				lesson: payload,
			});
		case ACTION_QUESTIONS:
		case ACTION_UPDATE_QUESTIONS:
			const questions = parseQuestions(payload);
			localStorage.setItem('project.questions', JSON.stringify(questions));
			return Object.assign({}, state, {
				...state,
				questions: questions,
			});
		case ACTION_UPDATE_OPTIONS:
		case ACTION_QUESTION_DETAIL:
		case ACTION_UPDATE_QUESTION:
			const question = parseQuestion(payload);
			localStorage.setItem('project.question', JSON.stringify(question));
			return Object.assign({}, state, {
				...state,
				question: question,
			});
		case ACTION_UPDATE_CONTENT:
		case ACTION_UPDATE_CONTENTS:
			const lesson2 = parseProjectLesson(payload);
			const content2 = updateContent(lesson2, state.content);
			localStorage.setItem('project.lesson', JSON.stringify(lesson2));
			localStorage.setItem('project.content', JSON.stringify(content2));
			return Object.assign({}, state, {
				...state,
				lesson: lesson2,
				content: content2,
			});
		case ACTION_LESSON_CONTENT:
			localStorage.setItem('project.content', JSON.stringify(payload));
			return Object.assign({}, state, {
				...state,
				content: payload,
			});
		case ACTION_UPLOAD_FILE:
			localStorage.setItem('project.files', JSON.stringify(payload));
			return Object.assign({}, state, {
				...state,
				files: payload,
			});
		default:
			return state;
	}
}
export default projectReducer;

function parseProjects(payload){
	return payload.map(function(unit, index, array){
		let titles = {};
		let descriptions = {};
		for (var i = unit.titles.length - 1; i >= 0; i--){
			titles['title-'+unit.titles[i].locale] = unit.titles[i].text;
		}
		for (var j = unit.descriptions.length - 1; j >= 0; j--){
			descriptions['description-'+unit.descriptions[j].locale] = unit.descriptions[j].text;
		}

		return {
			...titles,
			...descriptions,
			key: unit.uuid,
			teaching_hours: unit.teaching_hours,
			duration: unit.duration,
			review_assessment: unit.review_assessment,
			module_key: unit.module_key,
			priority: unit.priority,
			color: JSON.parse(unit.styles) ? JSON.parse(unit.styles)['background-color'] : '#ddd',
			icon: JSON.parse(unit.styles) ? JSON.parse(unit.styles).icon : '',
		};
	});
}

function parseTags(payload){
	return payload.map(function(unit, index, array){
		let titles = {};
		for (var i = unit.titles.length - 1; i >= 0; i--){
			titles['title-'+unit.titles[i].locale] = unit.titles[i].text;
		}

		return {
			...titles,
			key: unit.uuid,
			value: unit.uuid,
			label: unit.titles[0].text,
		};
	});
}

function parseQuestions(payload){
	return payload.map(function(unit, index, array){
		return parseQuestion(unit);
	});
}

function parseQuestion(payload){
	let images = {};
	let contents = {};
	let introductions = {};
	let options = payload.options ? payload.options : [];

	for (let i = payload.images.length - 1; i >= 0; i--){
		images['image-'+payload.images[i].locale] = payload.images[i].text;
	}
	for (let i = payload.contents.length - 1; i >= 0; i--){
		contents['content-'+payload.contents[i].locale] = payload.contents[i].text;
	}
	for (let i = payload.introductions.length - 1; i >= 0; i--){
		introductions['introduction-'+payload.introductions[i].locale] = payload.introductions[i].text;
	}
	if(payload.options){
		for(let j in options){
			for (let i = payload.options[j].contents.length - 1; i >= 0; i--){
				options[j]['content-'+payload.options[j].contents[i].locale] = payload.options[j].contents[i].text;
				options[j]['key'] = payload.options[j].uuid;
			}
		}
	}

	return {
		...images,
		...contents,
		...introductions,
		key: payload.uuid,
		type: payload.type,
		highlighted: JSON.parse(payload.highlighted),
		code: payload.code,
		options: options,
		pre_define: payload.pre_define,
		output_code: payload.output_code,
		expected_output: payload.expected_output,
	};
}

function parseLessons(payload){
	return payload.map(function(unit, index, array){
		let titles = {};
		let descriptions = {};
		for (var i = unit.titles.length - 1; i >= 0; i--){
			titles['title-'+unit.titles[i].locale] = unit.titles[i].text;
		}
		for (var j = unit.descriptions.length - 1; j >= 0; j--){
			descriptions['description-'+unit.descriptions[j].locale] = unit.descriptions[j].text;
		}

		return {
			...titles,
			...descriptions,
			key: unit.uuid,
			value: unit.uuid,
			extra: unit.extra,
		};
	});
}

function parseProjectDetail(payload, detail, lesson, content){
	const tags = payload.tags.map(({tag_uuid}) => tag_uuid);
	const lessons = parseProjectLessons(payload.lessons);
	const relatedProjects = parseRelatedProjects(payload.relatedProjects);
	const data = payload.data ? payload.data : detail;

	// update lesson
	if(lesson){
		for(let i in lessons){
			if(lessons[i].key === lesson.key){
				lesson = lessons[i];
				break;
			}
		}
	}

	// update content
	if(content){
		for(let i in lesson.content){
			if(lesson.content[i].key === content.key){
				content = lesson.content[i];
				break;
			}
		}
	}

	return {
		detail: {
			...data,
			tags: tags,
			lessons: lessons,
			relatedProjects: relatedProjects,
		},
		lesson: lesson,
		content: content,
	};
}

function parseProjectLessons(payload){
	return payload.map(function(unit, index, array){
		return parseProjectLesson(unit);
	});
}

function parseProjectLesson(payload){
	return {
		key: payload.lesson_uuid,
		mapping_uuid: payload.uuid,
		priority: payload.priority,
		content: parseLessonContent(payload.lessonContent)
	};
}

function parseLessonContent(payload){
	return payload.map(function(unit, index, array){
		let titles = {};
		let contents = {};
		let descriptions = {};
		for (var i = unit.titles.length - 1; i >= 0; i--){
			titles['title-'+unit.titles[i].locale] = unit.titles[i].text;
		}
		for (var j = unit.contents.length - 1; j >= 0; j--){
			contents['content-'+unit.contents[j].locale] = unit.contents[j].text;
		}
		for (var k = unit.descriptions.length - 1; k >= 0; k--){
			descriptions['description-'+unit.descriptions[k].locale] = unit.descriptions[k].text;
		}

		return {
			...titles,
			...contents,
			...descriptions,
			key: unit.uuid,
			type: unit.type,
			priority: unit.priority,
			question_uuid: unit.question_uuid,
			tags: unit.tags.map(a => a.tag_uuid),
		};
	});
}

function parseRelatedProjects(payload){
	return payload.map(function(unit, index, array){
		let titles = {};
		for (var i = unit.titles.length - 1; i >= 0; i--){
			titles['title-'+unit.titles[i].locale] = unit.titles[i].text;
		}

		return {
			...titles,
			key: unit.uuid,
			description: unit.description,
			image_src: unit.image_src,
			url: unit.url,
		};
	});
}

function updateContent(lesson, content){
	for (var i = lesson.content.length - 1; i >= 0; i--){
		if(lesson.content[i].key===content.key){
			return lesson.content[i];
		}
	}
	return content;
}
