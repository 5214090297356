import React from 'react';
import { ContextStore } from '../../../store/ContextStore';
import { updateTags } from '../../../actions/project.action';
import EditableTable from '../Common/EditableTable';

function Tags(props) {
	const { project } = React.useContext(ContextStore);

	const columns = [
		{
			title: '*title en-US',
			dataIndex: 'title-en-US',
			editable: true,
		},
		{
			title: '*title zh-HK',
			dataIndex: 'title-zh-HK',
			editable: true,
		},
		{
			title: '*title zh-CN',
			dataIndex: 'title-zh-CN',
			editable: true,
		},
	];

	const newData = {
		'title-en-US': 'Tag name',
		'title-zh-HK': 'Tag name',
		'title-zh-CN': 'Tag name',
	};

	return (
		<React.Fragment>
			<EditableTable 
				data={project.tags}
				columns={columns}
				newData={newData}
				api={updateTags}
			/>
		</React.Fragment>
	);
}
export default Tags;
