import React from 'react';
import { Table, Icon, Button, message } from 'antd';
import { DndProvider, DragSource, DropTarget } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import update from 'immutability-helper';

import { commonLang } from '../../../languages/common.language';
import { ContextStore } from '../../../store/ContextStore';

let dragingIndex = -1;

class BodyRow extends React.Component {
  render() {
    const { isOver, connectDragSource, connectDropTarget, moveRow, ...restProps } = this.props;
    const style = { ...restProps.style, cursor: 'move' };

    let { className } = restProps;
    if (isOver) {
      if (restProps.index > dragingIndex) {
        className += ' drop-over-downward';
      }
      if (restProps.index < dragingIndex) {
        className += ' drop-over-upward';
      }
    }

    return connectDragSource(
      connectDropTarget(<tr {...restProps} className={className} style={style} />),
    );
  }
}

const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index;
    return {
      index: props.index,
    };
  },
};

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;
    if (dragIndex === hoverIndex) {
      return;
    }
    props.moveRow(dragIndex, hoverIndex);
    monitor.getItem().index = hoverIndex;
  },
};

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource('row', rowSource, connect => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow),
);

function DragSortingTable(props) {
  const { dispatch } = React.useContext(ContextStore);
  const [state, setState] = React.useState({
    data: props.data,
    selectedRowKeys: props.selected,
    loading: false,
  });

  function moveRow(dragIndex, hoverIndex) {
    setState(
      update(state, {
        data: {
          $splice: [[dragIndex, 1], [hoverIndex, 0, state.data[dragIndex]]],
        },
      }),
    );
  }

  function onSelectChange(selectedRowKeys) {
    setState({ 
      ...state,
      selectedRowKeys: selectedRowKeys,
    });
  }

  function handleReset() {
    setState({
      ...state,
      data: props.data,
      selectedRowKeys: props.selected,
    });
  }

  async function handleSubmit() {
    setState({
      ...state,
      loading: true,
    });

    let added = [];
    let updated = [];
    let deleted = [];
    let final = [];

    for (var i = 0; i <= state.data.length - 1; i++) {
      // <----- only change order
      if(props.hideCheckbox){
        let record = state.data[i];
          record.priority = updated.length;
          updated.push(record);
      }
      // <----- add, update and delete
      else{
        if(state.selectedRowKeys.includes(state.data[i].key)){
          let record = state.data[i];
          record.priority = final.length;
          final.push(record);
          if(!props.selected.includes(record.key))
            added.push(record);
          else if(record !== props.data[props.data.length - props.selected.length + record.priority])
            updated.push(record);
        }
        else if(props.selected.includes(state.data[i].key)){
          deleted.push(state.data[i].key);
        }
      }
    }

    if(added.length>0 || updated.length>0 || deleted.length>0){
      dispatch(await props.api({added: added, updated: updated, deleted: deleted}));
      message.success(commonLang.updated);
    }

    setState({
      ...state,
      loading: false,
    });
  }

  const {selectedRowKeys } = state;
  const rowSelection = { 
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <React.Fragment>
      <div style={{height: 48}}>
        <Icon type="info-circle" /> {commonLang.drag_hints}
        <span style={{float: `right`}}>
          <Button type="outlined" onClick={handleReset} style={{marginLeft: 8}}>{commonLang.reset}</Button>
          <Button type="primary" onClick={handleSubmit} style={{marginLeft: 8}} loading={state.loading}>{commonLang.save}</Button>
        </span>
      </div>
      <DndProvider backend={HTML5Backend}>
        <Table
          columns={props.columns}
          rowSelection={props.hideCheckbox ? null : rowSelection}
          dataSource={state.data}
          components={{body: {row: DragableBodyRow}}}
          pagination={false}
          onRow={(record, index) => ({
            index,
            moveRow: moveRow,
          })}
        />
      </DndProvider>
    </React.Fragment>
  );
}

export default DragSortingTable;