import {
  ACTION_START_APP_LOADING,
  ACTION_END_APP_LOADING,
 } from '../constants/dom.constant';

export const startAppLoading = () => ({
  key: ACTION_START_APP_LOADING,
  payload: true,
});

export const endAppLoading = () => ({
  key: ACTION_END_APP_LOADING,
  payload: false,
});

export default {
  startAppLoading,
  endAppLoading,
};
