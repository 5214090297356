import React from 'react';
import { Link } from 'react-router-dom';
import { Button, message } from 'antd';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { ContextStore } from '../../../store/ContextStore';
import { commonLang } from '../../../languages/common.language';
import { ROUTE_PROJECTS, ROUTE_PROJECT_LESSON } from '../../../constants/app.constant';
import { RICH_TEXT } from '../../../constants/dom.constant';
import { 
  getProjectLesson, 
  updateProjectLessons,
  updateRelatedProjects,   
  updateProjectDescriptions,
} from '../../../actions/project.action';
import DraggableTable from '../Common/DraggableTable';
import EditableTable from '../Common/EditableTable';
import Tags from '../Common/Tags';

function Project(props) {
  const { dispatch, project } = React.useContext(ContextStore);
  const [values, setValue] = React.useState({
    'tags': project.detail.tags,
    'description-en-US': project.detail['description-en-US'],
    'description-zh-HK': project.detail['description-zh-HK'],
    'description-zh-CN': project.detail['description-zh-CN'],
    'loading': false,
  });

  const lessonColumns = [
    {
      title: 'title',
      dataIndex: 'title-en-US',
    },
    {
      title: '^content',
      dataIndex: 'content',
      render: (text, record) => <Button onClick={() => handleClickContent(record)}>{commonLang.details}</Button>
    },
  ];

  const projectColumns = [
    {
      title: '*title en-US',
      dataIndex: 'title-en-US',
      width: 150,
      editable: true,
    },
    {
      title: '*title zh-HK',
      dataIndex: 'title-zh-HK',
      width: 150,
      editable: true,
    },
    {
      title: '*title zh-CN',
      dataIndex: 'title-zh-CN',
      width: 150,
      editable: true,
    },
    {
      title: 'product',
      dataIndex: 'description',
      editable: true,
      render: (text, record) => <span>{record.description ? record.description : '-'}</span>
    },
    {
      title: 'url',
      dataIndex: 'url',
      editable: true,
      textarea: true,
    },
    {
      title: 'image_src',
      dataIndex: 'image_src',
      editable: true,
      textarea: true,
      width: 100,
    },
    {
      title: 'view',
      dataIndex: 'view',
      editable: true,
      render: (text, record) => <img alt="related project" src={record.image_src} style={{width: 150}} />
    },
  ];

  const newData = {
    'title-en-US': 'Project name',
    'title-zh-HK': 'Project name',
    'title-zh-CN': 'Project name',
    'description': null,
    'url': 'http://www.google.com',
    'image_src': 'https://computerhistory.org/wp-content/uploads/2019/06/16-9-dummy-image2.jpg',
  }

  // <----------- Descriptions
  function changeDescription(name, data) {
    let value = {};
    value[name] = data;
    setValue({
      ...values,
      ...value,
    });
  }

  async function saveDescription() {
    setValue({...values, loading: true});
    const data = {
      'description-en-US': values['description-en-US'],
      'description-zh-HK': values['description-zh-HK'],
      'description-zh-CN': values['description-zh-CN'],
    }
    dispatch(await updateProjectDescriptions(data));
    message.success(commonLang.updated);
    setValue({...values, loading: false});
  }

  // <----------- Lessons
  function mapOrder(array, order, key) {
    return array.sort(function(a, b) {
      return order.indexOf(a[key]) > order.indexOf(b[key]) ? 1 : -1;
    });
  };

  async function handleClickContent(lesson) {
    const item = project.detail.lessons.filter(item => item.key === lesson.key)[0];
    dispatch(await getProjectLesson(item, lesson['title-en-US']));
    props.history.push(ROUTE_PROJECT_LESSON);
  }

  const selectedLesson = project.detail.lessons.map(({key}) => key);
  const order = project.detail.lessons.map(({key}) => key);
  const orderedLesson = mapOrder(project.lessons, order, 'key');

  return (
    <div style={{width: `100%`}}>
      <h3 style={{marginBottom: 16}}><Link to={ROUTE_PROJECTS}>{commonLang.projects}</Link> / {project.detail['title-en-US']}</h3>
      <Tags target={project.detail.key} added={project.detail.tags} tags={project.tags} />

      <hr />
      <span style={{float: `right`}}>
        <Button type="primary" style={{marginLeft: 8}} onClick={saveDescription} loading={values.loading}>{commonLang.save}</Button>
      </span>
      <h2>{commonLang.descriptions}</h2>
      <h3>description-en-US</h3>
      <CKEditor
        editor={ClassicEditor}
        data={project.detail['description-en-US']}
        config={RICH_TEXT}
        onBlur={( event, editor ) => changeDescription('description-en-US', editor.getData())}
      />
      <br /><h3>description-zh-HK</h3>
      <CKEditor
        editor={ClassicEditor}
        data={project.detail['description-zh-HK']}
        config={RICH_TEXT}
        onBlur={( event, editor ) => changeDescription('description-zh-HK', editor.getData())}
      />
      <br /><h3>description-zh-CN</h3>
      <CKEditor
        editor={ClassicEditor}
        data={project.detail['description-zh-CN']}
        config={RICH_TEXT}
        onBlur={( event, editor ) => changeDescription('description-zh-CN', editor.getData())}
      />

      <hr />
      <h2>{commonLang.lessons}</h2>
      <DraggableTable 
        data={orderedLesson} 
        columns={lessonColumns}
        selected={selectedLesson}
        api={updateProjectLessons}
      />

      <hr />
      <h2>{commonLang.related_projects}</h2>
      <EditableTable 
        data={project.detail.relatedProjects}
        columns={projectColumns}
        newData={newData}
        api={updateRelatedProjects}
      />

    </div>
  );
}
export default Project;
