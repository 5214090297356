// <----- actions
export const ACTION_START_APP_LOADING = 'action_start_app_loading';
export const ACTION_END_APP_LOADING = 'action_end_app_loading';

// <----- z-indexes
export const Z_INDEX_MAIN = 1;
export const Z_INDEX_MAIN_HEADER = 99;
export const Z_INDEX_LOADING = 9999;

// <----- rich text editor
export const RICH_TEXT = {
	toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', "imageUpload", "mediaEmbed", '|', 'undo', 'redo', ],
	heading: {
		options: [
			{ model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
			{ model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
			{ model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
			{ model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
			{ model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' }
		]
	}
}

// <----- content type
export const TYPE_HTML = ['text/html'];
export const TYPE_AWS = ['href/amazon'];
export const TYPE_YOUTUBE = ['href/youtube'];
export const TYPE_IMAGE = [
	'image/bmp', 
	'image/gif', 
	'image/jpeg', 
	'image/png', 
	'image/svg+xml', 
	'image/tiff', 
	'image/webp'
];
export const TYPE_PDF = ['application/pdf'];
export const TYPE_OFFICE = [
	'application/vnd.ms-powerpoint', 
	'application/vnd.openxmlformats-officedocument.presentationml.presentation', 
	'application/msword', 
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 
	'application/vnd.ms-excel', 
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];
export const TYPE_EXPLORE = [
	'href/info', 
	'href/article', 
	'href/video', 
	'href/programming', 
];