import {
	ACTION_START_APP_LOADING,
	ACTION_END_APP_LOADING,
} from '../constants/dom.constant';
 
function domReducer(state, action) {
	const payload = action.payload;
	switch (action.key) {
		case ACTION_START_APP_LOADING:
		case ACTION_END_APP_LOADING:
			return Object.assign({}, state, {
					...state,
					loading: payload
				});
		default:
			return state;
	}
}
export default domReducer;
