import LocalizedStrings from 'react-localization';
// import { ServerAPIError } from '../utils/request';
// import { parseDomain } from '../utils/app';
 
export const errorLang = new LocalizedStrings({
	'en-us':{
		'40000': {
			'Not login yet': 'Your authorization key is incorrect, please login again.',
		},
		'domain': {
			'source': ' (Source from {0})',
		},
		'response': {
			'json_parsing_error': 'Server unexpected error.',
		},
	},
});

export const parseError = (error, showEndpoint = false) => {
	console.log('Method - parseError:',  error);
	// if(error && error instanceof ServerAPIError){
	// 	const domain = showEndpoint ? parseDomainToString(parseDomain(error.endpoint)) : '';
	// 	try{
	// 		if(errorLang[error.code][error.message])
	// 			return errorLang[error.code][error.message] + domain;
	// 		else
	// 			return error.message + domain;
	// 	}catch(e){
	// 		return error.message + domain;
	// 	}
	// }
	// return error;
}

// function parseDomainToString(domain){
// 	return domain ? errorLang.formatString(errorLang.domain.source, domain) : '';
// }

export default { errorLang, parseError };
