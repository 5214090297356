import {
	ACTION_CHANGE_LANGUAGE,
} from '../constants/app.constant';
import {
	publicLang
} from '../languages/public.language';
 
function appReducer(state, action) {
	const payload = action.payload;
	switch (action.key) {
		case ACTION_CHANGE_LANGUAGE:
			localStorage.setItem('app.language', payload);
			publicLang.setLanguage(payload);
			return Object.assign({}, state, {
					...state,
					language: payload
				});
		default:
			return state;
	}
}
export default appReducer;
