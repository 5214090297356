import { run } from '../utils/promise';
import { getPublicApi } from '../utils/api';
import {
	ACTION_AUTH_LOGIN,
	ACTION_AUTH_LOGOUT,
} from '../constants/auth.constant';

export const login = async (username, password) => {
	const body = {
		username: username, 
		password: password
	};
	const response = await getPublicApi().post(`/v1/auth/login`, body);
	return run(
		ACTION_AUTH_LOGIN,
		response.data,
	);
}

export const logout = async () => ({
	key: ACTION_AUTH_LOGOUT,
	payload: true,
});

export default {
	login,
	logout,
};
