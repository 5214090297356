import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { Layout } from 'antd';

import {
  ROUTE_PROJECTS,
  ROUTE_TAGS,
  ROUTE_PROJECT,
  ROUTE_LESSONS,
  ROUTE_QUESTIONS,
  ROUTE_QUESTION,
  ROUTE_PROJECT_LESSON,
  ROUTE_PROJECTS_ORDER,
  ROUTE_PROJECT_LESSON_CONTENT,
} from '../../constants/app.constant';
import { publicLang } from '../../languages/public.language';
import { Z_INDEX_MAIN } from '../../constants/dom.constant';
import MainHeader from './Common/MainHeader';
import TagsView from './Tags';
import LessonsView from './Lessons';
import QuestionsView from './Questions';
import QuestionView from './Questions/Question';
import ProjectsView from './Projects';
import ProjectView from './Projects/Project';
import ProjectOrderView from './Projects/Order';
import ProjectLessonView from './Projects/ProjectLesson';
import ProjectLessonContentView from './Projects/ProjectLessonContent';

const { Content, Footer } = Layout;

function Auth(props) {
  useEffect(() => {
    
  });

  return (
  		<React.Fragment>
        <MainHeader history={props.history} />
        <Content style={{ padding: '50px', zIndex: Z_INDEX_MAIN }}>
          <Route exact path={ROUTE_TAGS} component={TagsView} />
          <Route exact path={ROUTE_LESSONS} component={LessonsView} />
          <Route exact path={ROUTE_PROJECT} component={ProjectView} />
          <Route exact path={ROUTE_PROJECTS} component={ProjectsView} />
          <Route exact path={ROUTE_QUESTION} component={QuestionView} />
          <Route exact path={ROUTE_QUESTIONS} component={QuestionsView} />
          <Route exact path={ROUTE_PROJECTS_ORDER} component={ProjectOrderView} />
          <Route exact path={ROUTE_PROJECT_LESSON} component={ProjectLessonView} />
          <Route exact path={ROUTE_PROJECT_LESSON_CONTENT} component={ProjectLessonContentView} />
        </Content>
        <Footer style={{ textAlign: 'center' }}>{publicLang.footer}</Footer>
  	  </React.Fragment>
    );
}
export default Auth;
