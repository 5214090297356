import { message } from 'antd';
import { publicLang } from '../languages/public.language';
import {
  ACTION_AUTH_LOGIN,
  ACTION_AUTH_LOGOUT,
  VALUE_AUTH_MODULE,
 } from '../constants/auth.constant';
 import { defaultAuth } from '../store/ContextStore';

function authReducer(state, action) {
	const payload = action.payload;
	switch (action.key) {
		case ACTION_AUTH_LOGIN:
			const {userId, token, username, isAdmin} = parsePayloadFromAuthLogin(payload);
			localStorage.setItem('auth.isAdmin', isAdmin);
			if(!isAdmin){
				message.error(publicLang.no_permission);
				return state;
			}
			localStorage.setItem('auth.userId', userId);
			localStorage.setItem('auth.token', token);
			localStorage.setItem('auth.username', username);
			return Object.assign({}, state, {
				...state,
				userId: userId,
				token: token,
				username: username,
				isAdmin: isAdmin,
			});

		case ACTION_AUTH_LOGOUT:
			localStorage.clear();
			return Object.assign({}, state, defaultAuth);
		default:
			return state;
	}
}
export default authReducer;

function parsePayloadFromAuthLogin(payload){
	let isAdmin = false;
	payload.user.permissions.forEach((unit, index) => {
		if(unit.module_key===VALUE_AUTH_MODULE)
			isAdmin = true;
	});
	return {
		userId: payload.user.uuid,
		token: payload.jwt.token,
		username: payload.user.username,
		isAdmin: isAdmin,
	};
}
