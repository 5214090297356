import React from 'react';
import { Icon, Input, Button, Form } from 'antd';

import { login } from '../../actions/auth.action';
import { getProjects } from '../../actions/project.action';
import { ContextStore } from '../../store/ContextStore';
import { publicLang } from '../../languages/public.language';
import { startAppLoading, endAppLoading } from '../../actions/dom.action';
import { ROUTE_PROJECTS } from '../../constants/app.constant';

function Public(props) {
	const { dispatch } = React.useContext(ContextStore);

	async function handleLogin(e){
		e.preventDefault();

		const username = document.getElementById('login-username').value;
		const password = document.getElementById('login-password').value;
		if(!(username && password)) return;

		dispatch(startAppLoading());
		dispatch(await login(username, password));

		if(!localStorage.getItem('auth.token')){
			dispatch(endAppLoading());
			return;
		}

		dispatch(await getProjects());
		props.history.push(ROUTE_PROJECTS);
		dispatch(endAppLoading());
		return;
	}

	return (
		<div style={{maxWidth: `300px`, padding: `32px`, margin: `auto`, textAlign: `center`}}>
			<h3>{publicLang.smart_lab}</h3>
			<h1>{publicLang.admin_portal}</h1>
			<Form onSubmit={handleLogin}>
			<p><Input id='login-username' prefix={<Icon type="user" />} placeholder={publicLang.username} /></p>
			<p><Input id='login-password' prefix={<Icon type="lock" />} type="password" placeholder={publicLang.password} /></p>
			<p><Button type="primary" htmlType="submit">{publicLang.login}</Button></p>
			</Form>
		</div>
	);
}

export default Public;
