import React from 'react';
import { Button } from 'antd';

import { ContextStore } from '../../../store/ContextStore';
import { commonLang } from '../../../languages/common.language';
import { ROUTE_PROJECT } from '../../../constants/app.constant';
import { getProjectDetail, getTags, getLessons, updateProjects } from '../../../actions/project.action';
import EditableTable from '../Common/EditableTable';

function Projects(props) {
  const { dispatch, project } = React.useContext(ContextStore);

  async function handleClickDetail(project) {
    dispatch(await getTags());
    dispatch(await getLessons());
    dispatch(await getProjectDetail(project));
    props.history.push(ROUTE_PROJECT);
  }

  const columns = [
    {
      title: '*title en-US',
      dataIndex: 'title-en-US',
      width: 150,
      editable: true,
    },
    {
      title: '*title zh-HK',
      dataIndex: 'title-zh-HK',
      width: 150,
      editable: true,
    },
    {
      title: '*title zh-CN',
      dataIndex: 'title-zh-CN',
      width: 150,
      editable: true,
    },
    {
      title: '*duration',
      dataIndex: 'duration',
      editable: true,
    },
    {
      title: '*teaching hours',
      dataIndex: 'teaching_hours',
      width: 100,
      editable: true,
    },
    {
      title: '*review assessment',
      dataIndex: 'review_assessment',
      width: 150,
      editable: true,
    },
    {
      title: '*icon',
      dataIndex: 'icon',
      width: 100,
      editable: true,
      textarea: true,
    },
    {
      title: 'view',
      dataIndex: 'view',
      render: (text, record) => <img alt="icon" src={record.icon} />
    },
    {
      title: '^details',
      dataIndex: 'details',
      render: (text, record) => <Button onClick={() => handleClickDetail(record)} disabled={Number.isInteger(record.key)}>{commonLang.details}</Button>
    },
  ];

  const newData = {
    'title-en-US': 'Project name',
    'title-zh-HK': 'Project name',
    'title-zh-CN': 'Project name',
    'duration': 0,
    'teaching_hours': 0,
    'description-en-US': 'Project description',
    'description-zh-HK': 'Project description',
    'description-zh-CN': 'Project description',
    'review_assessment': 'included',
    'color': 'rgb(200, 200, 200)',
    'icon': '/images/AI-Art.svg',
    'priority': 99,
  };

  return (
    <React.Fragment>
      <EditableTable 
        data={project.all}
        columns={columns}
        newData={newData}
        api={updateProjects}
      />
    </React.Fragment>
  );
}
export default Projects;
