import React from "react";
import { LANGUAGE_EN_US } from '../constants/app.constant';

export const defaultApp = {
		language: localStorage.getItem('app.language') || LANGUAGE_EN_US,
	};
export const defaultDom = {
		loading: false,
	};
export const defaultAuth = {
		userId: localStorage.getItem('auth.userId') || null,
		token: localStorage.getItem('auth.token') || null,
	};
export const defaultProject = {
		all: localStorage.getItem('project.all') ? JSON.parse(localStorage.getItem('project.all')) : [],
		tags: localStorage.getItem('project.tags') ? JSON.parse(localStorage.getItem('project.tags')) : [],
		detail: localStorage.getItem('project.detail') ? JSON.parse(localStorage.getItem('project.detail')) : [],
		lesson: localStorage.getItem('project.lesson') ? JSON.parse(localStorage.getItem('project.lesson')) : [],
		lessons: localStorage.getItem('project.lessons') ? JSON.parse(localStorage.getItem('project.lessons')) : [],
		content: localStorage.getItem('project.content') ? JSON.parse(localStorage.getItem('project.content')) : [],
		questions: localStorage.getItem('project.questions') ? JSON.parse(localStorage.getItem('project.questions')) : [],
		question: localStorage.getItem('project.question') ? JSON.parse(localStorage.getItem('project.question')) : [],
		files: localStorage.getItem('project.files') ? JSON.parse(localStorage.getItem('project.files')) : [],
	};

export const ContextStore = React.createContext({
		app: defaultApp,
		dom: defaultDom,
		auth: defaultAuth,
		project: defaultProject,
	});
