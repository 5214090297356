// <----- actions
export const ACTION_PROJECTS = 'action_projects';
export const ACTION_PROJECT_DETAIL = 'action_project_detail';
export const ACTION_PROJECT_LESSON = 'action_project_lesson';
export const ACTION_LESSONS = 'action_lessons';
export const ACTION_TAGS = 'action_tags';
export const ACTION_QUESTIONS = 'action_questions';
export const ACTION_QUESTION_DETAIL = 'action_question_detail';
export const ACTION_LESSON_CONTENT = 'action_lesson_content';

export const ACTION_UPDATE_TAGS = 'action_update_tags';
export const ACTION_UPDATE_LESSONS = 'action_update_lessons';
export const ACTION_UPDATE_PROJECTS = 'action_update_projects';
export const ACTION_UPDATE_QUESTIONS = 'action_update_questions';
export const ACTION_UPDATE_OPTIONS = 'action_update_options';
export const ACTION_UPDATE_QUESTION = 'action_update_question';
export const ACTION_UPDATE_CONTENTS = 'action_update_contents';
export const ACTION_UPDATE_CONTENT = 'action_update_content';
export const ACTION_UPDATE_PROJECT_TAGS = 'action_update_project_tags';
export const ACTION_UPDATE_PROJECT_LESSONS = 'action_update_project_lessons';
export const ACTION_UPDATE_PROJECT_DESCRIPTIONS = 'action_update_project_descriptions';
export const ACTION_UPDATE_RELATED_PROJECTS = 'action_update_related_projects';
export const ACTION_UPLOAD_FILE = 'action_upload_file';