import { PROMISE_RUN } from '../constants/promise.constant';
import { message } from 'antd';

export const run = async (key, payload, options = { skipLoadingState: false }) => {
	if(payload.error && payload.error.message){
		message.error(payload.error.message);
		return {}
	}

	return {
		type: PROMISE_RUN,
		key: key,
		payload: await payload.data,
		...options
	}
};

export default {
  run
};
