import React, { useEffect } from 'react';
import Public from './Public';
import Auth from './Auth';
import './App.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import {
	defaultApp,
	defaultDom,
	defaultAuth,
	defaultProject,
	ContextStore
} from '../store/ContextStore';
import appReducer from "../reducers/app.reducer";
import domReducer from "../reducers/dom.reducer";
import authReducer from "../reducers/auth.reducer";
import projectReducer from "../reducers/project.reducer";
import Loading from "./Loading";
import ReactGA from 'react-ga';
import {
	GOOGLE_ANALYTICS,
	ROUTE_HOME,
} from '../constants/app.constant';
import {
	isAuthorized,
} from '../reducers/rules/auth.rules';

if(GOOGLE_ANALYTICS) ReactGA.initialize(GOOGLE_ANALYTICS);

function App() {
	const [appState, appDispatch] = React.useReducer(appReducer, defaultApp);
	const [domState, domDispatch] = React.useReducer(domReducer, defaultDom);
	const [authState, authDispatch] = React.useReducer(authReducer, defaultAuth);
	const [projectState, projectDispatch] = React.useReducer(projectReducer, defaultProject);

	function combineDispatchs(dispatchs) {
		return function(obj){
			for (let i = 0; i < dispatchs.length; i++)
			dispatchs[i](obj);
		}
	}

	useEffect(() => {

	});
	
	return (
		<div>
			<div>
				<ContextStore.Provider
				      value={{
				      	app: appState,
				      	dom: domState,
				        auth: authState,
				        project: projectState,
				        dispatch: combineDispatchs([
				        		appDispatch, 
				        		domDispatch, 
				        		authDispatch, 
				        		projectDispatch, 
				        	]),
				      }}
				    >
				    <Loading/>
					<BrowserRouter basename="" forceRefresh={false}>
						<Switch>
							{isAuthorized(authState) ? (
								<Switch>
									<Route path={ROUTE_HOME} component={Auth} />
								</Switch>
							) : (
								<Switch>
									<Route path={ROUTE_HOME} component={Public} />
								</Switch>
							)}
						</Switch>
					</BrowserRouter>
				</ContextStore.Provider>
			</div>
		</div>
  );
}
export default App;
