import LocalizedStrings from 'react-localization';
 
export const commonLang = new LocalizedStrings({
	'en-us':{
		"logout": "Logout",
		"projects": "Projects",
		"questions": "Questions",
		"tags": "Tags",
		"lessons": "Lessons",
		"projects_order": "Projects Order",
		"cms": "AI Smart Lab CMS",
		"add": "Add",
		"reset": "Reset",
		"save": "Save",
		"delete": "Delete",
		"details": "Details",
		"edit_hints": "Can edit cell content directly. Input '-' for empty cell",
		"detail_hints": "Please save before go to 'Details'",
		"drag_hints": "Drag and drop to change order",
		"related_projects": "Related Projects",
		"updated": "Updated",
		"descriptions": "Descriptions",
		"clear": "Clear",
	},
});

export default { commonLang };
