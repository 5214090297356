import React from 'react';
import { Button } from 'antd';
import { ContextStore } from '../../../store/ContextStore';
import { updateQuestions } from '../../../actions/project.action';
import { commonLang } from '../../../languages/common.language';
import { ROUTE_QUESTION } from '../../../constants/app.constant';
import { getQuestionDetail } from '../../../actions/project.action';
import EditableTable from '../Common/EditableTable';

function Questions(props) {
	const { dispatch, project } = React.useContext(ContextStore);

	const columns = [
		{
			title: '*type (code/image/text/editor)',
			dataIndex: 'type',
			editable: true,
			filters: [{text:'code',value:'code'},{text:'image',value:'image'},{text:'text',value:'text'},{text:'editor',value:'editor'}],
			onFilter: (value, record) => record.type.indexOf(value) === 0,
		},
		{
			title: '*content en-US',
			dataIndex: 'content-en-US',
			editable: true,
			width: 200,
			textarea: true,
		},
		{
			title: '*content zh-HK',
			dataIndex: 'content-zh-HK',
			editable: true,
			width: 200,
			textarea: true,
		},
		{
			title: '*content zh-CN',
			dataIndex: 'content-zh-CN',
			editable: true,
			width: 200,
			textarea: true,
		},
		{
	      title: '^content',
	      dataIndex: 'content',
	      render: (text, record) => <Button onClick={() => handleClickContent(record)}>{commonLang.details}</Button>
	    },
	];

	const newData = {
		'type': 'image',
		'content-en-US': 'Question title',
		'content-zh-HK': 'Question title',
		'content-zh-CN': 'Question title',
		'introduction-en-US': '<p></p>',
		'introduction-zh-HK': '<p></p>',
		'introduction-zh-CN': '<p></p>',
		'image-en-US': 'https://computerhistory.org/wp-content/uploads/2019/06/16-9-dummy-image2.jpg',
		'image-zh-HK': 'https://computerhistory.org/wp-content/uploads/2019/06/16-9-dummy-image2.jpg',
		'image-zh-CN': 'https://computerhistory.org/wp-content/uploads/2019/06/16-9-dummy-image2.jpg',
	};

	async function handleClickContent(question) {
		dispatch(await getQuestionDetail(question));
		props.history.push(ROUTE_QUESTION);
	}

	return (
		<React.Fragment>
			<EditableTable 
				data={project.questions}
				columns={columns}
				newData={newData}
				api={updateQuestions}
			/>
		</React.Fragment>
	);
}
export default Questions;
