import axios from 'axios';
import {
	PATH_API_BASE_URL,
} from '../constants/app.constant';
import {
	APPLICATION_JSON,
} from '../constants/common.constant';

let publicApi = null;
export const getPublicApi = (useCache=true) => {
	return publicApi && useCache
		? publicApi
		: publicApi = axios.create({
				baseURL: PATH_API_BASE_URL,
				headers: {
					get: {
						"Content-Type": APPLICATION_JSON,
					},
					post: {
						"Content-Type": APPLICATION_JSON,
					},
				},
			});
}

let authorizedApi = null;
export const getApi = (useCache=true) => {
	return authorizedApi && useCache
		? authorizedApi
		: authorizedApi = axios.create({
				baseURL: PATH_API_BASE_URL,
				headers: {
					get: {
						"Content-Type": APPLICATION_JSON,
						'Authorization': 'Bearer ' + localStorage.getItem('auth.token'),
					},
					post: {
						"Content-Type": APPLICATION_JSON,
						'Authorization': 'Bearer ' + localStorage.getItem('auth.token'),
					},
					put: {
						"Content-Type": APPLICATION_JSON,
						'Authorization': 'Bearer ' + localStorage.getItem('auth.token'),
					},
					delete: {
						"Content-Type": APPLICATION_JSON,
						'Authorization': 'Bearer ' + localStorage.getItem('auth.token'),
					},
				},
			});
}
