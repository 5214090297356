import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Input, Modal, Row, Col, Radio } from 'antd';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { ContextStore } from '../../../store/ContextStore';
import { commonLang } from '../../../languages/common.language';
import { 
  ROUTE_PROJECTS,
  ROUTE_PROJECT,
 } from '../../../constants/app.constant';
import { 
  RICH_TEXT,
  TYPE_HTML,
  TYPE_EXPLORE,
} from '../../../constants/dom.constant';
import { 
  updateContent,
} from '../../../actions/project.action';
import Previewer from '../Common/Previewer';
import Tags from '../Common/Tags';

function ProjectLessonContent(props) {
  const { dispatch, project } = React.useContext(ContextStore);
  const [values, setValue] = React.useState({
    ...project.content,
    'loading': false,
  });

  function changeValue(name, data) {
    let value = {};
    value[name] = data;
    setValue({...values, ...value});
  }

  async function handleSave(data) {
    setValue({...values, loading: true});

    dispatch(await updateContent(data));

    Modal.success({
      title: commonLang.updated,
      onOk() {
        return new Promise((resolve, reject) => {
          window.location.reload();
        }).catch(() => console.log('Oops errors!'));
      },
    });
    setValue({...values, loading: false});
  }

  function parseContent() {
    let content;
    if(TYPE_HTML.includes(values.type)){
      content = parseInspectContent();
    }
    if(TYPE_EXPLORE.includes(values.type)){
      content = parseExploreContent();
    }
    else{
      content = parseSlideContent();
    }
    return content;
  }

  function parseInspectContent(){
    return (
      <div>
        <h3>content-en-US</h3>
        <CKEditor
          editor={ClassicEditor}
          data={project.content['content-en-US']}
          config={RICH_TEXT}
          onBlur={( event, editor ) => changeValue('content-en-US', editor.getData())}
        />
        <br /><h3>content-zh-HK</h3>
        <CKEditor
          editor={ClassicEditor}
          data={project.content['content-zh-HK']}
          config={RICH_TEXT}
          onBlur={( event, editor ) => changeValue('content-zh-HK', editor.getData())}
        />
        <br /><h3>content-zh-CN</h3>
        <CKEditor
          editor={ClassicEditor}
          data={project.content['content-zh-CN']}
          config={RICH_TEXT}
          onBlur={( event, editor ) => changeValue('content-zh-CN', editor.getData())}
        />
      </div>
    );
  }

  function parseSlideContent(){
    return(
      <Row gutter={16}>
        <Col md={8}>
          <h3>content-en-US</h3>
          <Input.TextArea defaultValue={values['content-en-US']} onChange={(e) => changeValue('content-en-US', e.target.value)} />
          <Previewer mime={values.type} src={values['content-en-US']} />
        </Col>
        <Col md={8}>
          <h3>content-zh-HK</h3>
          <Input.TextArea defaultValue={values['content-zh-HK']} onChange={(e) => changeValue('content-zh-HK', e.target.value)} />
          <Previewer mime={values.type} src={values['content-zh-HK']} />
        </Col>
        <Col md={8}>
          <h3>content-zh-CN</h3>
          <Input.TextArea defaultValue={values['content-zh-CN']} onChange={(e) => changeValue('content-zh-CN', e.target.value)} />
          <Previewer mime={values.type} src={values['content-zh-CN']} />
        </Col>
      </Row>
    );
  }

  function parseExploreContent(){
    return(
      <Row gutter={16}>
        <Col md={8}>
          <h3>content-en-US</h3>
          <Input.TextArea defaultValue={values['content-en-US']} onChange={(e) => changeValue('content-en-US', e.target.value)} />
          <h3>description-en-US</h3>
          <Input.TextArea defaultValue={values['description-en-US']} onChange={(e) => changeValue('description-en-US', e.target.value)} />
        </Col>
        <Col md={8}>
          <h3>content-zh-HK</h3>
          <Input.TextArea defaultValue={values['content-zh-HK']} onChange={(e) => changeValue('content-zh-HK', e.target.value)} />
          <h3>description-zh-HK</h3>
          <Input.TextArea defaultValue={values['description-zh-HK']} onChange={(e) => changeValue('description-zh-HK', e.target.value)} />
        </Col>
        <Col md={8}>
          <h3>content-zh-CN</h3>
          <Input.TextArea defaultValue={values['content-zh-CN']} onChange={(e) => changeValue('content-zh-CN', e.target.value)} />
          <h3>description-zh-CN</h3>
          <Input.TextArea defaultValue={values['description-zh-CN']} onChange={(e) => changeValue('description-zh-CN', e.target.value)} />
        </Col>
      </Row>
    );
  }

  const questions = project.questions.map((unit, index) => <Col md={12} key={unit.key}><Radio value={unit.key}>{unit['content-en-US']}</Radio></Col>)

  return (
    <div style={{width: `100%`}}>
      <h3 style={{marginBottom: 16}}>
        <Link to={ROUTE_PROJECTS}>{commonLang.projects}</Link> / 
        <Link to={ROUTE_PROJECT}>{project.detail['title-en-US']}</Link> / 
        {project.content['title-en-US']}
      </h3>

      <Tags target={project.content.key} added={project.content.tags} tags={project.tags} />
 
      <span style={{float: `right`}}>
        <Button type="primary" style={{marginLeft: 8}} onClick={() => handleSave(values)} loading={values.loading}>{commonLang.save}</Button>
      </span>
      <h2>Content ({values.type})</h2>
      { parseContent() }

      <hr />
      <span style={{float: `right`}}>
        <Button type="default" style={{marginLeft: 8}} onClick={() => changeValue('question_uuid', null)}>{commonLang.clear}</Button>
      </span>
      <h2>Question</h2>
      <Radio.Group onChange={(e) => changeValue('question_uuid', e.target.value)} value={values.question_uuid}>
        <Row gutter={16}>{ questions }</Row>
      </Radio.Group>

    </div>
  );
}
export default ProjectLessonContent;
