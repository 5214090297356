import { run } from '../utils/promise';
import { getApi } from '../utils/api';
import { fileToBase64 } from '../utils/file';
import {
	ACTION_PROJECTS,
	ACTION_PROJECT_DETAIL,
	ACTION_PROJECT_LESSON,
	ACTION_LESSONS,
	ACTION_TAGS,
	ACTION_QUESTIONS,
	ACTION_QUESTION_DETAIL,
	ACTION_LESSON_CONTENT,

	ACTION_UPDATE_TAGS,
	ACTION_UPDATE_LESSONS,
	ACTION_UPDATE_OPTIONS,
	ACTION_UPDATE_PROJECTS,
	ACTION_UPDATE_CONTENT,
	ACTION_UPDATE_CONTENTS,
	ACTION_UPDATE_QUESTIONS,
	ACTION_UPDATE_QUESTION,
	ACTION_UPDATE_PROJECT_TAGS,
	ACTION_UPDATE_PROJECT_LESSONS,
	ACTION_UPDATE_RELATED_PROJECTS,
	ACTION_UPDATE_PROJECT_DESCRIPTIONS,
	ACTION_UPLOAD_FILE,
} from '../constants/project.constant';

export const getProjects = async () => {
	const response = await getApi().get(`/v1/cms/projects`);
	return run(
		ACTION_PROJECTS,
		response.data
	);
}

export const getProjectDetail = async (project) => {
	const response = await getApi().get(`/v1/cms/project?uuid=`+project.key);
	const data = {
		...response.data,
		data: {
			...response.data.data,
			data: project
		}
	};

	return run(
		ACTION_PROJECT_DETAIL,
		data,
	);
}

export const getLessons = async () => {
	const response = await getApi().get(`/v1/cms/lessons`);
	return run(
		ACTION_LESSONS,
		response.data
	);
}

export const getTags = async () => {
	const response = await getApi().get(`/v1/cms/tags`);
	return run(
		ACTION_TAGS,
		response.data
	);
}

export const getQuestions = async () => {
	const response = await getApi().get(`/v1/cms/questions`);
	return run(
		ACTION_QUESTIONS,
		response.data
	);
}

export const getQuestionDetail = async (question) => {
	const response = await getApi().get(`/v1/cms/question?uuid=`+question.key);
	return run(
		ACTION_QUESTION_DETAIL,
		response.data
	);
}

export const getProjectLesson = async (lesson, lessonName) => {
	return run(
		ACTION_PROJECT_LESSON,
		{data: {...lesson, name: lessonName}}
	);
}

export const getProjectLessonContent = async (content) => {
	return run(
		ACTION_LESSON_CONTENT,
		{data:content},
	);
}

export const updateTags = async (data) => {
	const response = await getApi().post(`/v1/cms/tags`, data);
	return run(
		ACTION_UPDATE_TAGS,
		response.data
	);
}

export const updateQuestions = async (data) => {
	const response = await getApi().post(`/v1/cms/questions`, data);
	return run(
		ACTION_UPDATE_QUESTIONS,
		response.data
	);
}

export const updateQuestion = async (data) => {
	const response = await getApi().post(`/v1/cms/question`, data);
	return run(
		ACTION_UPDATE_QUESTION,
		response.data
	);
}

export const updateLessons = async (data) => {
	const response = await getApi().post(`/v1/cms/lessons`, data);
	return run(
		ACTION_UPDATE_LESSONS,
		response.data
	);
}

export const updateProjects = async (data) => {
	const response = await getApi().post(`/v1/cms/projects`, data);
	return run(
		ACTION_UPDATE_PROJECTS,
		response.data
	);
}

export const updateContents = async (data) => {
	data.project = JSON.parse(localStorage.getItem('project.detail')).key;
	data.lessonMapping = JSON.parse(localStorage.getItem('project.lesson')).mapping_uuid;
	const response = await getApi().post(`/v1/cms/lesson_contents`, data);
	return run(
		ACTION_UPDATE_CONTENTS,
		response.data
	);
}

export const updateContent = async (data) => {
	data.lessonMapping = JSON.parse(localStorage.getItem('project.lesson')).mapping_uuid;
	const response = await getApi().post(`/v1/cms/lesson_content`, data);
	return run(
		ACTION_UPDATE_CONTENT,
		response.data
	);
}

export const updateOptions = async (data) => {
	data.question = JSON.parse(localStorage.getItem('project.question')).key;
	const response = await getApi().post(`/v1/cms/question_options`, data);
	return run(
		ACTION_UPDATE_OPTIONS,
		response.data
	);
}

export const updateProjectTags = async (target, added, deleted) => {
	const data = {
		project: target,
		added: added,
		deleted: deleted,
	};
	const response = await getApi().post(`/v1/cms/project_tags`, data);
	return run(
		ACTION_UPDATE_PROJECT_TAGS,
		response.data
	);
}

export const updateProjectDescriptions = async (data) => {
	const project = JSON.parse(localStorage.getItem('project.detail')).key;
	const response = await getApi().post(`/v1/cms/project_descriptions`, {data: data, project: project});
	return run(
		ACTION_UPDATE_PROJECT_DESCRIPTIONS,
		response.data
	);
}

export const updateProjectLessons = async (data) => {
	data.project = JSON.parse(localStorage.getItem('project.detail')).key;
	const response = await getApi().post(`/v1/cms/project_lessons`, data);
	return run(
		ACTION_UPDATE_PROJECT_LESSONS,
		response.data
	);
}

export const updateRelatedProjects = async (data) => {
	data.project = JSON.parse(localStorage.getItem('project.detail')).key;
	const response = await getApi().post(`/v1/cms/related_projects`, data);
	return run(
		ACTION_UPDATE_RELATED_PROJECTS,
		response.data
	);
}

export const uploadFile = async (file) => {
	const data = {
			'fileData': await fileToBase64(file),
			'fileName': file.name,
			'fileType': file.type,
		};
	const response = await getApi().post(`/v1/cms/file/upload`, data);
	return run(
		ACTION_UPLOAD_FILE,
		response.data
	);
}

export default {
	getProjects,
	getProjectDetail,
	getProjectLesson,
	getProjectLessonContent,
	getQuestions,
	getQuestionDetail,
	getLessons,
	getTags,
	updateTags,
	updateLessons,
	updateOptions,
	updateProjects,
	updateContent,
	updateContents,
	updateQuestion,
	updateQuestions,
	updateProjectTags,
	updateProjectLessons,
	updateRelatedProjects,
	updateProjectDescriptions,
	uploadFile,
};
