import React from 'react';
import { ContextStore } from '../store/ContextStore';
import ClipLoader from 'react-spinners/ClipLoader';
import {
	Z_INDEX_LOADING,
} from '../constants/dom.constant';

const styles = {
	loadingContainer:{
		position: `fixed`,
		width: `100%`,
		height: `100%`,
		textAlign: `center`,
		backgroundColor: `rgba(0, 0, 0, 0.1)`,
		zIndex: Z_INDEX_LOADING,
	},
	loadingIcon: {
	    display: `block`,
	    margin: `0 auto`,
	    borderColor: `red`,
	    position: `relative`,
	    top: `50%`,
	},
};

function Loading() {
	const { dom } = React.useContext(ContextStore);
	return dom.loading && (
		<div style={styles.loadingContainer}>
			<div style={styles.loadingIcon}>
				<ClipLoader
					sizeUnit={"px"}
					size={20}
					color={'#1968FF'}
					loading={dom.loading}
					/>
			</div>
		</div>
	);
}
export default Loading;
