import React from 'react';

import { ContextStore } from '../../../store/ContextStore';
import { commonLang } from '../../../languages/common.language';
import {
  updateProjects,
} from '../../../actions/project.action';
import DraggableTable from '../Common/DraggableTable';

function Order(props) {
  const { project } = React.useContext(ContextStore);

  const lessonColumns = [
    {
      title: 'title',
      dataIndex: 'title-en-US',
    },
  ];

  return (
    <div style={{width: `100%`}}>
      <h2>{commonLang.projects_order}</h2>
      <DraggableTable 
        data={project.all} 
        columns={lessonColumns}
        api={updateProjects}
        hideCheckbox={true}
      />

    </div>
  );
}
export default Order;